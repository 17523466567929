
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import Toast from '@/components/Toast.vue';

interface Category {
  id: string;
  name: string;
  color: string;
}

interface Post {
  id: string;
  title: string;
  subtitle: string;
  text: string;
  author: string;
  date: string;
  image: string;
  category: Category[];
}

export default defineComponent({
  name: 'BlogPost',
  components: {
    Toast,
  },
  setup() {
    const route = useRoute();
    const post = ref<Post | null>(null);
    const loading = ref(true);
    const error = ref<string | null>(null);
    const toast = ref<InstanceType<typeof Toast> | null>(null);

    const formatDate = (dateString: string) => {
      if (!dateString) return '';
      const date = new Date(dateString);
      const months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
      return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
    };
    
    const getRichTextContent = (richTextArray: { plain_text: string }[]): string => {
      if (!richTextArray || richTextArray.length === 0) return '';
      return richTextArray.map(item => item.plain_text).join('');
    };

    const showShareFeedback = (platform: string) => {
      if (toast.value) {
        switch(platform) {
          case 'facebook':
          case 'twitter':
            toast.value.show(`Compartiendo en ${platform}...`, "");
            break;
          case 'instagram':
            toast.value.show('URL copiada al portapapeles. Abre Instagram para compartir.', "");
            break;
        }
      }
    };
    
    const shareOnFacebook = () => {
      const url = encodeURIComponent(window.location.href);
      const title = encodeURIComponent(post.value?.title || '');
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`, '_blank');
      showShareFeedback('facebook');
    };

    const shareOnTwitter = () => {
      const url = encodeURIComponent(window.location.href);
      const text = encodeURIComponent(post.value?.title || '');
      window.open(`https://twitter.com/intent/tweet?url=${url}&text=${text}`, '_blank');
      showShareFeedback('twitter');
    };

    const shareOnInstagram = () => {
      navigator.clipboard.writeText(window.location.href).then(() => {
        showShareFeedback('instagram');
      });
    };
    
    const fetchPost = async () => {
      const postId = route.params.id as string;
      loading.value = true;
      error.value = null;

      try {
        const response = await fetch(`https://backend-test.tango.reprocard.com/notion/post/${postId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        post.value = {
          id: data.id,
          title: getRichTextContent(data.properties.Title.title),
          subtitle: getRichTextContent(data.properties['Sub-title'].rich_text),
          text: getRichTextContent(data.properties.Text.rich_text),
          author: getRichTextContent(data.properties.Autor.rich_text) || 'Anónimo',
          date: data.properties['Fecha Publicación']?.date?.start || '',
          image: data.properties['Imagen 1']?.files[0]?.file.url || '/placeholder-image.jpg',
          category: data.properties.Category?.multi_select || []
        };
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.error('Error fetching post:', err.message);
        } else {
          console.error('An unknown error occurred');
        }
        error.value = 'No se pudo cargar el post. Por favor, intenta más tarde.';
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchPost();
    });

    return {
      post,
      loading,
      error,
      toast,
      formatDate,
      shareOnFacebook,
      shareOnTwitter,
      shareOnInstagram
    };
  }
});
